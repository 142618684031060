<template>
  <div>
    <v-row justify="center">
      <v-dialog
        v-model="shower"
        max-width="800px"
        persistent
        scrollable
        v-if="resolutionScreen >= 500"
      >
        <v-card>
          <!-- //! Manage Version -->
          <v-card-title
            >{{ $t("tagVersion.manageversion") }}<v-spacer></v-spacer>
            <v-icon :color="color.theme" dense dark size="35"
              >mdi-tag-multiple</v-icon
            >
          </v-card-title>
          <v-divider></v-divider>
          <br />
          <v-card-text style="height:550px">
            <v-layout justify-center row wrap align-center>
              <v-flex lg11>
                <v-card v-if="createprogress === false" class="elevation-0">
                  <v-tabs v-model="currenttab">
                    <!-- //! View Version -->
                    <v-tab>{{ $t("tagVersion.viewversion") }}</v-tab>
                    <!-- //! Add new Version -->
                    <v-tab>{{ $t("tagVersion.addversion") }}</v-tab>
                    <v-tab-item>
                      <!--//! TAB1 View Version -->
                      <v-layout>
                        <v-flex lg12>
                          <v-list>
                            <v-list-item-group>
                              <v-list-item
                                v-for="(item, index) in tagversionfile"
                                :key="index"
                                @dblclick="openpreviewfile(item)"
                              >
                                <v-list-item-icon>
                                  <v-layout column justify-center wrap>
                                    <v-icon :color="item.file_icon[1]">{{
                                      item.file_icon[0]
                                    }}</v-icon>
                                    <v-chip
                                      v-if="tagversionfile.length > 1"
                                      x-small
                                      class="short d-flex justify-center"
                                      :color="
                                        item.tag_status === 'false'
                                          ? '#ffcc00'
                                          : 'success'
                                      "
                                      text-color="#ffffff"
                                      >{{
                                        item.tag_status === "true"
                                          ? "Main"
                                          : "Sub"
                                      }}
                                    </v-chip>
                                    <v-chip
                                      v-else
                                      x-small
                                      class="short d-flex justify-center"
                                      color="success"
                                      text-color="#ffffff"
                                      >Main
                                    </v-chip>
                                  </v-layout>
                                </v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-title>{{
                                    item.file_name
                                  }}</v-list-item-title>
                                  <v-list-item-subtitle
                                    :title="item.tag_detail"
                                    >{{ item.tag_detail }}</v-list-item-subtitle
                                  >
                                  <v-list-item-subtitle>{{
                                    fn_formatdatetime(item.cre_dtm)
                                  }}</v-list-item-subtitle>
                                  <v-list-item-subtitle
                                    v-if="$t('default') === 'th'"
                                    >แก้ไขโดย
                                    {{
                                      item.update_by_name_th
                                    }}</v-list-item-subtitle
                                  >
                                  <v-list-item-subtitle v-else
                                    >Update by
                                    {{
                                      item.update_by_name_en
                                    }}</v-list-item-subtitle
                                  >
                                </v-list-item-content>
                                <v-list-item-action>
                                  <v-btn
                                    fab
                                    small
                                    outlined
                                    :color="color.theme"
                                    @click="
                                      fn_opentagdialogoption($event, index)
                                    "
                                  >
                                    <v-icon>more_vert</v-icon>
                                  </v-btn>
                                </v-list-item-action>
                              </v-list-item>
                            </v-list-item-group>
                          </v-list>
                        </v-flex>
                      </v-layout>
                    </v-tab-item>
                    <v-tab-item>
                      <!-- //! TAB2 Add new Version -->
                      <!-- //! Tag name and Tag type -->
                      <v-layout>
                        <v-flex lg11 xs10 sm10>
                          <v-card-title>
                            {{ showtagname.tag_name }}
                          </v-card-title>
                          <v-card-subtitle>
                            {{ $t("tagVersion.startuploadorselectpdf") }}
                            <span color="error">
                              {{ showtagname.tag_type }}
                            </span>
                            {{ $t("tagVersion.enduploadorselectpdf") }}
                          </v-card-subtitle>
                        </v-flex>
                      </v-layout>
                      <!-- //! Radio btn -->
                      <v-layout justify-start align-center row class="mt-0">
                        <v-flex lg11 xs10>
                          <v-radio-group
                            row
                            class="ml-8"
                            v-model="changeaddtag"
                          >
                            <v-radio
                              :label="$t('tagVersion.uploadbtn')"
                              value="upload"
                            ></v-radio>
                            <v-radio
                              :label="$t('tagVersion.addbtn')"
                              value="add"
                            ></v-radio>
                          </v-radio-group>
                        </v-flex>
                      </v-layout>
                      <!-- //! Add file -->
                      <v-layout
                        justify-center
                        align-center
                        row
                        class="mt-3"
                        v-if="changeaddtag == 'add'"
                      >
                        <v-flex lg2 xs2 sm1>
                          <v-btn
                            @click="opendialogaddtagversion = true"
                            fab
                            x-small
                            class="elevation-5"
                            :color="color.theme"
                            dark
                          >
                            <v-icon>add</v-icon>
                          </v-btn>
                        </v-flex>
                        <v-flex lg9 xs8 sm9>
                          <v-row>
                            <v-icon :color="addfile.file_icon[1]">{{
                              addfile.file_icon[0]
                            }}</v-icon>
                            {{ addfile.file_name }}
                            <v-icon
                              @click="
                                addfile = {
                                  file_icon: ['', ''],
                                  file_name: '',
                                  file_type: '',
                                }
                              "
                              v-if="addfile.file_name != ''"
                              color="red"
                              class="ml-2"
                              >close</v-icon
                            >
                            <div v-if="validateaddfile == true" class="pl-4">
                              <span
                                v-if="
                                  textValidateType == true &&
                                    addfile.file_type != ''
                                "
                                style="color:red;"
                                >ประเภทไฟล์ไม่ถูกต้อง</span
                              >
                              <span
                                v-if="
                                  textValidateFile == true &&
                                    addfile.file_name == ''
                                "
                                style="color:red;"
                                >กรุณาเลือกไฟล์</span
                              >
                            </div>
                          </v-row>
                        </v-flex>
                      </v-layout>
                      <!-- //! Upload File -->
                      <v-layout
                        justify-center
                        align-center
                        row
                        class="mt-3"
                        v-else
                      >
                        <v-flex lg10 xs10>
                          <v-file-input
                            v-model="fileinput"
                            outlined
                            dense
                            :label="$t('tagVersion.filelabel')"
                            ref="fileinput"
                            truncate-length="50"
                            :rules="[rules.requiredFile, rules.requiredType]"
                          ></v-file-input>
                        </v-flex>
                        <v-flex lg1 xs9 sm1 class="pb-4 ml-0">
                          <v-progress-circular
                            :value="uploadPercentage"
                            color="green"
                            :size="resolutionScreen >= 400 ? 50 : 35"
                            width="5"
                            v-if="fileinput != undefined"
                            >{{ uploadPercentage }}</v-progress-circular
                          >
                        </v-flex>
                      </v-layout>
                      <v-layout justify-center align-center row>
                        <v-flex lg11 xs10>
                          <!-- //! เพิ่ม remark -->
                          <br v-if="changeaddtag == 'add'" />
                          <!-- <v-card-text><v-icon small>create</v-icon> {{$t('tagVersion.detail')}}</v-card-text> -->
                          <v-textarea
                            outlined
                            :label="$t('tagVersion.detail')"
                            :placeholder="'*' + $t('tagVersion.remark')"
                            v-model="addcomment"
                            :rules="[rules.requiredText]"
                            ref="addcomment"
                          ></v-textarea>
                        </v-flex>
                      </v-layout>
                      <v-layout justify-end align-center row class="pb-3">
                        <!-- //! Update button -->
                        <v-flex lg3 xs6 sm3
                          ><v-btn
                            :disabled="check_btn"
                            @click="fn_selectfunction()"
                            color="primary"
                            >{{ $t("tagVersion.updatebtn") }}</v-btn
                          ></v-flex
                        >
                      </v-layout>
                    </v-tab-item>
                  </v-tabs>
                </v-card>
                <v-flex lg12 v-else>
                  <v-layout justify-center row wrap fill-height align-center>
                    <v-progress-circular
                      class="mt-6 mb-6 pt-6 pb-6"
                      :size="50"
                      :dark="color.darkTheme"
                      :color="color.theme"
                      indeterminate
                    ></v-progress-circular>
                  </v-layout>
                </v-flex>
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <!-- //! close button -->
            <v-btn
              v-if="statusclose === true"
              color="error"
              @click="$emit('closedialog'), fn_cleardata()"
              >{{ $t("tagVersion.closebtn") }}</v-btn
            >
            <v-btn
              v-else
              color="error"
              @click="$emit('closedialognorefresh'), fn_cleardata()"
              >{{ $t("tagVersion.closebtn") }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- หน้าจอมือถือ -->
      <v-dialog
        v-model="shower"
        scrollable
        persistent
        :max-width="maxWidthOnMobile"
        content-class="rounded-lg"
        v-else
      >
        <v-card>
          <v-card-text class="pa-5">
            <v-layout class="pt-2 pb-4">
              <v-flex d-flex justify-center class="ml-8">
                <span :style="headerPage">{{
                  $t("tagVersion.manageversion")
                }}</span>
              </v-flex>
              <v-flex xs1 d-flex justify-end>
                <v-icon
                  v-if="statusclose === true"
                  :color="$vuetify.theme.dark ? 'white' : color.theme"
                  @click="$emit('closedialog'), fn_cleardata()"
                  >mdi-close</v-icon
                >
                <v-icon
                  v-else
                  :color="$vuetify.theme.dark ? 'white' : color.theme"
                  @click="$emit('closedialognorefresh'), fn_cleardata()"
                  >mdi-close</v-icon
                >
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-tabs
            v-if="createprogress === false"
            v-model="currenttab"
            fixed-tabs
            class="px-1"
          >
            <!-- //! View Version -->
            <v-tab>{{ $t("tagVersion.viewversion") }}</v-tab>
            <!-- //! Add new Version -->
            <v-tab>{{ $t("tagVersion.addversion") }}</v-tab>
            <v-tab-item class="scroller">
              <!--//! TAB1 View Version -->
              <v-layout>
                <v-flex lg12>
                  <v-list>
                    <v-list-item-group>
                      <v-list-item
                        v-for="(item, index) in tagversionfile"
                        :key="index"
                        @click="openpreviewfile(item)"
                      >
                        <v-list-item-icon>
                          <v-layout column justify-center wrap>
                            <v-icon :color="item.file_icon[1]">{{
                              item.file_icon[0]
                            }}</v-icon>
                            <v-chip
                              v-if="tagversionfile.length > 1"
                              x-small
                              class="short d-flex justify-center"
                              :color="
                                item.tag_status === 'false'
                                  ? '#ffcc00'
                                  : 'success'
                              "
                              text-color="#ffffff"
                              >{{ item.tag_status === "true" ? "Main" : "Sub" }}
                            </v-chip>
                            <v-chip
                              v-else
                              x-small
                              class="short d-flex justify-center"
                              color="success"
                              text-color="#ffffff"
                              >Main
                            </v-chip>
                          </v-layout>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>{{
                            item.file_name
                          }}</v-list-item-title>
                          <v-list-item-subtitle :title="item.tag_detail">{{
                            item.tag_detail
                          }}</v-list-item-subtitle>
                          <v-list-item-subtitle>{{
                            fn_formatdatetime(item.cre_dtm)
                          }}</v-list-item-subtitle>
                          <v-list-item-subtitle v-if="$t('default') === 'th'"
                            >แก้ไขโดย
                            {{ item.update_by_name_th }}</v-list-item-subtitle
                          >
                          <v-list-item-subtitle v-else
                            >Update by
                            {{ item.update_by_name_en }}</v-list-item-subtitle
                          >
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-btn
                            fab
                            small
                            class="elevation-0"
                            icon
                            @click.stop="fn_opentagdialogoption($event, index)"
                          >
                            <v-icon>mdi-dots-horizontal</v-icon>
                          </v-btn>
                        </v-list-item-action>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-flex>
              </v-layout>
            </v-tab-item>
            <v-tab-item class="scroller">
              <!-- //! TAB2 Add new Version -->
              <!-- //! Tag name and Tag type -->
              <v-layout>
                <v-flex lg11 xs10 sm10>
                  <v-card-title>
                    {{ showtagname.tag_name }}
                  </v-card-title>
                  <v-card-subtitle>
                    {{ $t("tagVersion.startuploadorselectpdf") }}
                    <span color="error">
                      {{ showtagname.tag_type }}
                    </span>
                    {{ $t("tagVersion.enduploadorselectpdf") }}
                  </v-card-subtitle>
                </v-flex>
              </v-layout>
              <!-- //! Radio btn -->
              <v-layout justify-start align-center row class="mt-0">
                <v-flex lg11 xs10>
                  <v-radio-group row class="ml-8" v-model="changeaddtag">
                    <v-radio
                      :label="$t('tagVersion.uploadbtn')"
                      value="upload"
                      class="mb-2"
                    ></v-radio>
                    <v-radio
                      :label="$t('tagVersion.addbtn')"
                      value="add"
                    ></v-radio>
                  </v-radio-group>
                </v-flex>
              </v-layout>
              <!-- //! Add file -->
              <v-layout
                justify-center
                align-center
                row
                class="mt-3"
                v-if="changeaddtag == 'add'"
              >
                <v-flex lg2 xs2 sm1>
                  <v-btn
                    @click="opendialogaddtagversion = true"
                    fab
                    x-small
                    class="elevation-5"
                    :color="color.theme"
                    dark
                  >
                    <v-icon>add</v-icon>
                  </v-btn>
                </v-flex>
                <v-flex lg9 xs8 sm9>
                  <v-row>
                    <v-icon :color="addfile.file_icon[1]">{{
                      addfile.file_icon[0]
                    }}</v-icon>
                    {{ addfile.file_name }}
                    <v-icon
                      @click="
                        addfile = {
                          file_icon: ['', ''],
                          file_name: '',
                          file_type: '',
                        }
                      "
                      v-if="addfile.file_name != ''"
                      color="red"
                      class="ml-2"
                      >close</v-icon
                    >
                    <div v-if="validateaddfile == true" class="pl-4">
                      <span
                        v-if="
                          textValidateType == true && addfile.file_type != ''
                        "
                        style="color:red;"
                        >ประเภทไฟล์ไม่ถูกต้อง</span
                      >
                      <span
                        v-if="
                          textValidateFile == true && addfile.file_name == ''
                        "
                        style="color:red;"
                        >กรุณาเลือกไฟล์</span
                      >
                    </div>
                  </v-row>
                </v-flex>
              </v-layout>
              <!-- //! Upload File -->
              <v-layout justify-center align-center row class="mt-3" v-else>
                <v-flex lg10 xs10>
                  <v-file-input
                    v-model="fileinput"
                    outlined
                    dense
                    :label="$t('tagVersion.filelabel')"
                    ref="fileinput"
                    truncate-length="50"
                    :rules="[rules.requiredFile, rules.requiredType]"
                  ></v-file-input>
                </v-flex>
                <v-flex lg1 xs9 sm1 class="pb-4 ml-0">
                  <v-progress-circular
                    :value="uploadPercentage"
                    color="green"
                    :size="resolutionScreen >= 400 ? 50 : 35"
                    width="5"
                    v-if="fileinput != undefined"
                    >{{ uploadPercentage }}</v-progress-circular
                  >
                </v-flex>
              </v-layout>
              <v-layout justify-center align-center row>
                <v-flex lg11 xs10>
                  <!-- //! เพิ่ม remark -->
                  <br v-if="changeaddtag == 'add'" />
                  <!-- <v-card-text><v-icon small>create</v-icon> {{$t('tagVersion.detail')}}</v-card-text> -->
                  <v-textarea
                    outlined
                    :label="$t('tagVersion.detail')"
                    :placeholder="'*' + $t('tagVersion.remark')"
                    v-model="addcomment"
                    :rules="[rules.requiredText]"
                    ref="addcomment"
                  ></v-textarea>
                </v-flex>
              </v-layout>
              <v-layout justify-end align-center wrap row class="pb-3">
                <!-- //! Update button -->
                <v-flex xs5
                  ><v-btn
                    :disabled="check_btn"
                    @click="fn_selectfunction()"
                    color="primary"
                    >{{ $t("tagVersion.updatebtn") }}</v-btn
                  ></v-flex
                >
              </v-layout>
            </v-tab-item>
          </v-tabs>
          <v-card-text class="text-center" v-else>
            <v-progress-circular
              class="mt-6 mb-6 pt-6 pb-6"
              :size="50"
              :dark="color.darkTheme"
              :color="color.theme"
              indeterminate
            ></v-progress-circular>
          </v-card-text>
          <div class="text-center my-4">
            <v-btn
              v-if="statusclose === true"
              :style="btnAction"
              class="mr-2"
              height="40px"
              width="40%"
              color="red"
              outlined
              @click="$emit('closedialog'), fn_cleardata()"
              :disabled="createprogress"
              >{{ $t("tagVersion.closebtn") }}</v-btn
            >
            <v-btn
              v-else
              :style="btnAction"
              class="mr-2"
              height="40px"
              width="40%"
              color="red"
              outlined
              @click="$emit('closedialognorefresh'), fn_cleardata()"
              :disabled="createprogress"
              >{{ $t("tagVersion.closebtn") }}</v-btn
            >
          </div>
        </v-card>
      </v-dialog>
    </v-row>
    <!-- //! Component -->
    <tagversionoptional
      :show="opendialogoption"
      :filedata="sendfiletooption"
      :AreaX="positionX"
      :AreaY="positionY"
      :activefileID="activatefile"
      :parentfolder="parentfolder"
      @closecurrentonly="opendialogoption = false"
      @reload="fn_view_version(), (statusclose = true)"
    ></tagversionoptional>
    <previewfile
      :show="opendialogpreviewfile"
      :arrayfile="arrayfilepreview"
      :filedata_1="openfileforpreview"
      :parentfolder="parentfolder"
      @closepreviewfile="opendialogpreviewfile = false"
      @openpreviewfile="opendialogpreviewfile = true"
    ></previewfile>
    <addtagversion
      :show="opendialogaddtagversion"
      :currentfile="filedata"
      :parentfolder="parentfolder"
      @filerecive="getrecivefile"
      @closecurrentonly="opendialogaddtagversion = false"
      @reload="fn_view_version"
    ></addtagversion>
    <uploadfiles
      :show="opendialogupload"
      :parentfolder="parentfolder"
      :remark="addcomment"
      :switchapiupload="changeapiupload"
      :oldactivefile="filedata.file_id"
      @close="
        (changeapiupload = ''), (opendialogupload = false), fn_view_version()
      "
    >
    </uploadfiles>
  </div>
</template>

<script>
//! import Lib
import { mapState, mapGetters } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});
//! import Component
const tagversionoptional = () =>
  import("@/components/optional/dialog-tagversionoptional");
const previewfile = () => import("./dialog-preview");
const addtagversion = () =>
  import("@/components/optional/dialog-addtagversion");
const uploadfiles = () => import("../upload/dialog-uploadfiles2");

export default {
  watch: {
    fileinput(val) {
      console.log("fileinput", val);
      if (val == undefined || val == null || val == "") {
        this.uploadPercentage = 0;
        // ? พอกดไฟล์ทิ้ง fileinput จะเป็น undefined เลยกำหนดให้มันเป็น null
        this.fileinput = null;
      }
    },
    changeaddtag(val) {
      console.log("clear validate when change tag");
      console.log("change tag", val);
      // ? เวลาเปลี่ยน tab ให้มันไปเคลียค่าใน input field ทิ้งให้หมด
      this.fn_resetValidate();
    },
  },
  computed: {
    ...mapState([
      "username",
      "authorize",
      "account_active",
      "color",
      "role_level",
    ]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
    ]),
    shower: {
      get() {
        if (this.show === true) {
          this.createprogress = true;
          setTimeout(() => {
            this.fn_view_version();
          }, 500);
          return true;
        }
      },
      set(value) {},
    },
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    // style อักษร header ของ mobile
    headerPage() {
      return (
        "color:" +
        this.color.theme +
        ";" +
        "font-size: 16px; font-weight: 600; font-weight: bold; letter-spacing: 0px;"
      );
    },
    // style ปุ่ม ของ mobile
    btnAction() {
      return "font-size: 16px; font-weight: lighter;";
    },
    // ความกว้างของ dialog ของ ipad และ mobile
    maxWidthOnMobile() {
      if (this.resolutionScreen >= 400) {
        return 600;
      } else {
        return 346;
      }
    },
  },
  components: { tagversionoptional, previewfile, addtagversion, uploadfiles },
  props: ["show", "filedata", "parentfolder"],
  data() {
    return {
      arrayfilepreview: [],
      check_btn: false,
      showtagname: {
        tag_name: "",
        tag_type: "",
      },
      textValidateType: false,
      textValidateFile: false,
      validateaddfile: false,
      typeFile: false,
      rules: {
        requiredText: (value) => !!value || "Required Text.",
        requiredFile: (value) => !!value || "Required File.",
        requiredType: () => this.fn_checkFileTypeUpload() || "wrong type",
      },
      addcomment: "",
      createprogress: false,
      tagversionfile: [],
      sendfiletooption: [],
      selectindex: "",
      positionX: 0,
      positionY: 0,
      opendialogoption: false,
      activatefile: "",
      opendialogpreviewfile: false,
      openfileforpreview: [],
      opendialogaddtagversion: false,
      addfile: { file_icon: ["", ""], file_name: "", file_type: "" },
      changeapiupload: "",
      opendialogupload: false,
      changeaddtag: "upload",
      fileinput: null,
      currenttab: 0,
      uploadPercentage: 0,
      statusclose: false,
    };
  },
  methods: {
    //! โหลดไฟล์ในตัว Tag version
    async fn_view_version() {
      let auth = await gbfGenerate.generateToken();
      console.log("filedata", this.filedata);
      let payload = {
        file_id: this.filedata.file_id,
        folder_id: this.parentfolder,
        tag_id: this.filedata.tag_id || "",
      };
      this.axios
        .post(
          process.env.VUE_APP_SERVICE_SELECT_FILE + "/api/all_file_tag_version",
          payload,
          {
            headers: { Authorization: auth.code },
          }
        )
        .then((response) => {
          console.log("tag-version response", response);
          this.tagversionfile = [];
          this.activatefile = "";
          if (response.data.status === "OK") {
            console.log("OK", response.data.result.length);
            for (let index = 0; index < response.data.result.length; index++) {
              let file_version = {};
              const element = response.data.result[index];
              file_version.account_id = element.account_id;
              file_version.business_id = element.business_id;
              file_version.cre_dtm = element.cre_dtm;
              file_version.data_type = element.data_type;
              file_version.file_icon = this.fn_seticon(element.data_type);
              file_version.file_id = element.file_id;
              file_version.file_name = element.filename;
              file_version.header_info = element.header_info;
              file_version.size_file = element.size_file;
              file_version.status_file = element.status_file;
              file_version.system = element.system;
              file_version.tag_detail = element.tag_detail;
              file_version.tag_id = element.tag_version_id;
              file_version.tag_name = element.tag_name;
              file_version.tag_status = element.tag_status;
              file_version.update_by_name_en = element.update_by_name_en;
              file_version.update_by_name_th = element.update_by_name_th;

              //? ตั้งตัว main
              if (element.tag_status === "true") {
                this.activatefile = element.file_id;
                this.showtagname.tag_name = element.tag_name;
                this.showtagname.tag_type = element.data_type;
              }
              this.tagversionfile.push(file_version);
            }
            this.createprogress = false;
          } else {
            // ? status !== OK อาจจะเป็น ER ให้ขึ้น Toast และปิด dialog
            // ? errorMessage ไม่เหมือนกัน
            Toast.fire({
              icon: "error",
              title:
                response.data.errorCode + " : " + response.data.errorMessage,
            });
            this.$emit("closedialog");
          }
        })
        .catch((e) => {
          // ? เข้ากรณี status != 200
          console.log("error fn_view_version =>", e);
          Toast.fire({
            icon: "error",
            title: "ไม่สามารถใช้งาน tag version ได้ในขณะนี้",
          });
          this.$emit("closedialog");
        });
    },
    //! เลือก function
    fn_selectfunction() {
      if (this.changeaddtag == "upload") {
        //? อัปไฟล์
        this.fn_submitfile();
      } else {
        //? เลือกไฟล์
        this.fn_addtagversionfile();
      }
    },
    //! เช็คว่านามสกุลไฟล์เหมือนกันไหม ppt != pptx สำหรับ validate การ uploadfile
    fn_checkFileTypeUpload() {
      if (this.fileinput !== null) {
        let splitFileType = this.fileinput.name.split(".");
        console.log("type", this.filedata);
        if (this.filedata.file_type === "file") {
          // ? ส่งมาเป็น file ของ advancesearch
          console.log("%cadvanceSearch upload tag", "color:red;");
          if (
            splitFileType[splitFileType.length - 1].toLowerCase() !==
            this.filedata.data_type.toLowerCase()
          ) {
            this.typeFile = false;
            console.log(
              "wrong type => input type: ",
              splitFileType[splitFileType.length - 1],
              "default type: ",
              this.filedata.data_type,
              " Allow upload:",
              this.typeFile
            );
            return false;
          } else {
            this.typeFile = true;
            console.log(
              "same type => input type: ",
              splitFileType[splitFileType.length - 1],
              "default type: ",
              this.filedata.data_type,
              " Allow upload:",
              this.typeFile
            );
            return true;
          }
        } else {
          // ? แบบปกติ
          if (
            splitFileType[splitFileType.length - 1].toLowerCase() !==
            this.filedata.file_type.toLowerCase()
          ) {
            this.typeFile = false;
            console.log(
              "wrong type => input type: ",
              splitFileType[splitFileType.length - 1],
              "default type: ",
              this.filedata.file_type,
              " Allow upload:",
              this.typeFile
            );
            return false;
          } else {
            this.typeFile = true;
            console.log(
              "same type => input type: ",
              splitFileType[splitFileType.length - 1],
              "default type: ",
              this.filedata.file_type,
              " Allow upload:",
              this.typeFile
            );
            return true;
          }
        }
      } else {
        console.log("fn_checkFileTypeUpload() file === null");
      }
    },
    //! อัปไฟล์
    async fn_submitfile() {
      //? ดักว่าต้องให้มี addcomment ถึงจะยิง api และเป็น file นามสกุลเหมือนกัน ppt != pptx
      console.log("อัปไฟล์");
      this.check_btn = true;
      if (
        this.addcomment !== "" &&
        this.fileinput !== null &&
        this.typeFile === true
      ) {
        const formData = new FormData();
        formData.append("file", this.fileinput);
        formData.append("size_file", this.fileinput.size);
        formData.append("folder_id", this.filedata.folder_id);
        formData.append("user_id", this.dataUsername);
        formData.append("department_id", this.dataDepartmentAccessId);
        formData.append("account_id", this.dataAccountId);
        formData.append("remark", this.addcomment || "");
        formData.append("old_version_file_id", this.filedata.file_id || "");
        console.log("formdata", formData);
        let auth = await gbfGenerate.generateToken();
        await this.axios
          .post(
            process.env.VUE_APP_SERVICE_UPLOAD_FILE + "/api/upload_files_tag",
            formData,
            {
              headers: { Authorization: auth.code },
              onUploadProgress: (progressEvent) => {
                this.uploadPercentage = parseInt(
                  Math.round((progressEvent.loaded / progressEvent.total) * 100)
                );
              },
            }
          )
          .then((response) => {
            console.log("upload res", response);
            if (response.data.status === "OK") {
              this.check_btn = false;
              //? อัปเสร็จกับไป Tab แรก
              this.fn_view_version();
              this.currenttab = 0;
              //? เคลียค่าออก
              this.fileinput = null;
              //? reset validate ทิ้ง
              this.fn_resetValidate();
              this.statusclose = true;
            } else {
              this.check_btn = false;
              // ? status !== OK อาจจะเป็น ER ให้ขึ้น Toast และปิด dialog
              // ? errorMessage ไม่เหมือนกัน
              Toast.fire({
                icon: "error",
                title:
                  response.data.errorCode + " : " + response.data.errorMessage,
              });
              this.$emit("closedialog");
            }
          })
          .catch((error) => {
            console.log("error upload", error);
          });
      } else {
        this.check_btn = false;
        console.log(
          "submit validate => type: ",
          this.typeFile,
          " comment: ",
          this.addcomment,
          this.check_btn
        );
        Toast.fire({
          icon: "error",
          title: "ไม่มีคอมเม้น ไฟล์ หรือ ไฟล์ผิดประเภท",
        });
        this.$refs["addcomment"].validate(true);
        this.$refs["fileinput"].validate(true);
      }
    },

    //! รับไฟล์มาจากหน้า dialog-addtagversion
    getrecivefile(val) {
      console.log("get val", val);
      this.addfile = val;
      this.textValidateFile = false;
      this.fn_checkFileTypeAdd();
    },
    //! เช็คว่านามสกุลไฟล์เหมือนกันไหม ppt != pptx สำหรับ validate การ addfile
    fn_checkFileTypeAdd() {
      if (this.filedata.file_type === "file") {
        // ? จาก advanceSearch ส่งมาเป็น file
        console.log("%cadvanceSearch add Tag", "color:red;");
        if (
          this.addfile.file_type.toLowerCase() ===
          this.filedata.data_type.toLowerCase()
        ) {
          //? ไฟล์ตรงกัน validate ให้เป็น false และไม่อนุญาติให้อัปไฟล์เข้าไป (typeFile = true)
          this.textValidateType = false;
          this.validateaddfile = false;
          this.typeFile = true;
        } else {
          //? ไฟล์ไม่ตรงกัน validate ให้เป็น true และไม่อนุญาติให้อัปไฟล์เข้าไป (typeFile = false)
          this.textValidateType = true;
          this.validateaddfile = true;
          this.typeFile = false;
        }
      } else {
        // ? แบบปกติ

        if (
          this.addfile.file_type.toLowerCase() ===
          this.filedata.file_type.toLowerCase()
        ) {
          //? ไฟล์ตรงกัน validate ให้เป็น false และไม่อนุญาติให้อัปไฟล์เข้าไป (typeFile = true)
          this.textValidateType = false;
          this.validateaddfile = false;
          this.typeFile = true;
        } else {
          //? ไฟล์ไม่ตรงกัน validate ให้เป็น true และไม่อนุญาติให้อัปไฟล์เข้าไป (typeFile = false)
          this.textValidateType = true;
          this.validateaddfile = true;
          this.typeFile = false;
        }
      }
    },
    //! เพิ่มไฟล์เข้า tag version
    async fn_addtagversionfile() {
      this.check_btn = true;
      let auth = await gbfGenerate.generateToken();
      if (
        this.addcomment !== "" &&
        this.addfile.file_icon[0] !== "" &&
        this.addfile.file_icon[1] !== "" &&
        this.addfile.file_name !== "" &&
        this.typeFile == true
      ) {
        let payload = {
          //? ถ้าไม่มีตัว activatefile จะเอาไฟล์ปัจจุบันไปแทน
          old_version_file_id: this.activatefile || this.filedata.file_id,
          new_version_file_id: this.addfile.file_id,
          remark: this.addcomment,
          folder_id: this.filedata.folder_id,
          account_id: this.dataAccountId,
        };
        this.axios
          .post(
            process.env.VUE_APP_SERVICE_UPDATE_MULTIPLES +
              "/api/update_tag_version",
            payload,
            {
              headers: { Authorization: auth.code },
            }
          )
          .then((response) => {
            console.log("addtag response", response);
            if (response.data.status === "OK") {
              this.check_btn = false;
              this.fn_cleardata();
              //? แบบปิด dialog
              // this.$emit("closedialog");
              //? แบบไป tab แรก
              this.fn_view_version();
              this.currenttab = 0;
              //? reset validate ทิ้ง
              this.fn_resetValidate();
              this.statusclose = true;
            } else {
              this.check_btn = false;
              // ? status !== OK อาจจะเป็น ER ให้ขึ้น Toast และปิด dialog
              // ? errorMessage ไม่เหมือนกัน
              Toast.fire({
                icon: "error",
                title:
                  response.data.errorCode + " : " + response.data.errorMessage,
              });
            }
          })
          .catch((e) => {
            console.log("error fn_addtagversionfile:", e);
          });
      } else {
        // console.log('else addcomment',this.addfile.file_icon[0] === '',this.addfile.file_icon[1] === '',this.addfile.file_name === '')
        // ? ไม่มีคอมเม้น ไฟล์ หรือ ไฟล์ผิดประเภท
        Toast.fire({
          icon: "error",
          title: "ไม่มีคอมเม้น ไฟล์ หรือ ไฟล์ผิดประเภท",
        });
        this.$refs["addcomment"].validate(true);
        this.validateaddfile = true;
        this.textValidateFile = true;
        this.check_btn = false;
      }
    },
    // เซ็ต icon
    fn_seticon(parameter) {
      let dataicon;
      if (parameter === "xlsx" || parameter === "xls") {
        dataicon = ["mdi-file-excel-outline", "green"];
      } else if (parameter === "pptx" || parameter === "ppt") {
        dataicon = ["mdi-file-powerpoint-outline", "orange"];
      } else if (parameter === "docx" || parameter === "doc") {
        dataicon = ["mdi-file-word-outline", "primary"];
      } else if (parameter === "zip") {
        dataicon = ["mdi-zip-box-outline", "blue-grey"];
      } else if (parameter === "pdf") {
        dataicon = ["mdi-file-pdf-outline", "red"];
      } else if (parameter === "xml") {
        dataicon = ["mdi-file-code-outline", "orange"];
      } else if (parameter === "txt" || parameter === "txt") {
        dataicon = ["mdi-note-text-outline", "blue-grey"];
      } else if (
        parameter === "jpeg" ||
        parameter === "jpg" ||
        parameter === "png"
      ) {
        dataicon = ["mdi-file-image-outline", "blue-grey"];
      } else if (
        parameter === "avi" ||
        parameter === "mpeg" ||
        parameter === "mov" ||
        parameter === "mp4" ||
        parameter === "mkv" ||
        parameter === "wmv" ||
        parameter === "3gp" ||
        parameter === "flv"
      ) {
        dataicon = ["mdi-file-video-outline", "purple"];
      } else if (parameter === "shortcut") {
        dataicon = ["mdi-file-link-outline", "pink"];
      } else {
        dataicon = ["mdi-file-outline", "black"];
      }
      return dataicon;
    },
    openpreviewfile(item) {
      let filename = item.file_name.split(".");
      item.file_type = filename[filename.length - 1];
      this.arrayfilepreview.push(item);
      this.openfileforpreview = item;
      this.opendialogpreviewfile = true;
      console.log("itemmmmmm", this.openfileforpreview);
      console.log("item value", item.file_id);
      console.log("parent", this.parentfolder);
    },
    //! แปลงค่าเวลา
    fn_formatdatetime(_datetime) {
      if (_datetime === "" || _datetime === "-" || _datetime === undefined) {
        return "-";
      } else {
        let dateyear =
          _datetime.split("")[0] +
          _datetime.split("")[1] +
          _datetime.split("")[2] +
          _datetime.split("")[3];
        let datemonth = _datetime.split("")[4] + _datetime.split("")[5];
        let dateday = _datetime.split("")[6] + _datetime.split("")[7];
        let hour = _datetime.split("")[8] + _datetime.split("")[9];
        let minute = _datetime.split("")[10] + _datetime.split("")[11];
        let second = _datetime.split("")[12] + _datetime.split("")[13];

        return (
          dateday +
          "/" +
          datemonth +
          "/" +
          dateyear +
          " " +
          hour +
          ":" +
          minute +
          ":" +
          second
        );
      }
    },
    //! reset validate
    fn_resetValidate() {
      console.log("resetValidate");
      // this.$refs["addcomment"].reset();
      // this.$refs["fileinput"].reset();
      this.addcomment = "";
      this.fileinput = null;
    },
    //! reset ค่ากลับไปค่าเริ่มต้น
    fn_cleardata() {
      console.log("cleardate");
      this.changeaddtag = "upload";
      this.addcomment = "";
      this.fileinput = null;
      this.createprogress = false;
      this.tagversionfile = [];
      this.sendfiletooption = [];
      this.selectindex = "";
      this.positionX = 0;
      this.positionY = 0;
      this.opendialogoption = false;
      this.activatefile = "";
      this.addfile = { file_icon: ["", ""], file_name: "", file_type: "" };
      this.typeFile = false;
      this.showtagname = { tag_name: "", tag_type: "" };
      this.statusclose = false;
    },
    //! เปิด dialog-optional
    fn_opentagdialogoption(event, index) {
      event.preventDefault();
      this.$nextTick(() => {
        this.opendialogoption = false;
        setTimeout(() => {
          this.positionX = event.clientX;
          this.positionY = event.clientY;
          this.opendialogoption = true;
        }, 300);
      });
      this.sendfiletooption = this.tagversionfile[index];
    },
  },
};
</script>

<style>
.short {
  width: 60px;
}
.scroller {
  height: 370px;
  overflow-y: scroll;
  /* scrollbar-color: rebeccapurple green;
  scrollbar-width: thin; */
  overflow-x: hidden;
}
</style>
